import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useLinkTo } from '@react-navigation/native'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { USER_STATUS } from 'integration/resources/auth'
import {
  ContentSerialized,
  useGetKnowledgeBasesInfiniteQuery
} from 'integration/resources/knowledgeBase'
import { useGetOpportunitiesSummaryQuery } from 'integration/resources/newOpportunities'
import {
  TNotification,
  useGetAllNotificationsInfinityQuery,
  NOTIFICATION_TYPES
} from 'integration/resources/notifications'
import { useGetNpsQuery, useUpdateNpsMutation } from 'integration/resources/nps'
import { TIndicatorResume, useGetIndicatorResumeQuery } from 'integration/resources/rvIndicator'
import { useGetProfileQuery } from 'integration/resources/user'
import { useGetRvSummaryQuery } from 'integration/resources/variable-revenue/hooks'
import { RvSummary } from 'integration/resources/variable-revenue/types'
import { useBreakpointValue, useDisclose } from 'native-base'
import { TArcProgressWalletProps } from 'organisms/ArcProgress/Points/PointsArcProgress.types'
import { useSetIsOpenNewTermAtom } from 'organisms/BottomSheetNewTerm'
import { rolesGR } from 'organisms/RVCalculators/Utils/constants'
import { useQueryClient } from 'react-query'
import { useAuthAtomValue } from 'src/store/auth'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { UseHomeScreen } from './HomeScreen.types'
import { LIST_TYPES_NAMES, ListType } from '../OrganizationScreen/OrganizationScreen.types'

const daysOfWeek = {
  dom: 'Dom',
  sab: 'Sáb',
  seg: 'Seg',
  ter: 'Ter',
  qua: 'Qua',
  qui: 'Qui',
  sex: 'Sex'
}

const setProgressValue = (data: TIndicatorResume) => ({
  potentials: data.rv_potentials ?? [],
  isAwaitingReceiveFullRvData: data.is_awaiting_receive_full_rv_data ?? false,
  msgAwaitingReceiveFullRvData: data.msg_awaiting_receive_full_rv_data ?? '',
  realized: {
    current: data.total_real_points ?? 0,
    pointsAmount: data.total_real_points ?? 0,
    currencyAmount: data.total_real_value ?? 0
  },
  simulated: {
    current: Number(data.rv_simulation_goal_points) ?? 0,
    pointsAmount: data.rv_simulation_goal_points ?? 0,
    currencyAmount: data.rv_simulation_goal_value ?? 0
  },
  projected: {
    current: Number(data.total_projection_points) ?? 0,
    pointsAmount: data.total_projection_points ?? 0,
    currencyAmount: data.total_projection_value ?? 0
  }
})

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetKnowledgeBasesInfiniteQuery>['data']
) =>
  data?.pages.reduce<ContentSerialized[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useHomeScreen: UseHomeScreen = ({ navigation, route }) => {
  navigation.setOptions({
    title: 'Tela inicial'
  })

  const linkTo = useLinkTo()

  const [refreshing, setRefreshing] = useState(false)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const queryClient = useQueryClient()

  const authAtom = useAuthAtomValue()

  const setIsOpenNewTermAtom = useSetIsOpenNewTermAtom()

  const isGoodsAndServices = useMemo(
    () => authAtom?.user?.business_code === 3,
    [authAtom?.user?.business_code]
  )

  const userNeedsToAcceptNewTerms = useMemo(
    () =>
      authAtom &&
      authAtom?.user?.status === USER_STATUS.AWAITING_ACCEPT_TERM &&
      (authAtom?.user?.accepted_commitment_term_at ||
        authAtom?.user?.accepted_responsibility_term_at),
    [authAtom]
  )

  useEffect(() => {
    if (userNeedsToAcceptNewTerms) {
      setIsOpenNewTermAtom(true)
    }
  }, [setIsOpenNewTermAtom, userNeedsToAcceptNewTerms])

  const { data: dataNps } = useGetNpsQuery()

  const { mutate: updateNpsMutation } = useUpdateNpsMutation()

  const {
    data: opportunitiesData,
    refetch: refetchOpportunitiesSummary,
    isLoading: isLoadingOpportunitiesSummary
  } = useGetOpportunitiesSummaryQuery()

  const opportunitiesSummary = useMemo(
    () => opportunitiesData?.data.data,
    [opportunitiesData?.data.data]
  )

  const opportunitiesSummaryIsEmpty = useMemo(
    () =>
      !(
        opportunitiesSummary?.odc.id ??
        opportunitiesSummary?.activeBase.id ??
        opportunitiesSummary?.insuranceAuto.id ??
        opportunitiesSummary?.charge.id
      ),
    [opportunitiesSummary]
  )

  const {
    isLoading: isLoadingProfile,
    data: getQueryProfileData,
    refetch: refetchProfile
  } = useGetProfileQuery()

  const profile = useMemo(() => getQueryProfileData?.data.data, [getQueryProfileData?.data.data])

  const userIsGR = useMemo(() => {
    const occupation = profile?.occupation ?? ''

    return rolesGR.includes(occupation)
  }, [profile?.occupation])

  const userName =
    profile?.name && profile?.name.split(' ').length > 1
      ? profile?.name.split(' ')[0]
      : profile?.name

  const imageProfile = profile?.profile_image_url

  const today = new Date()

  const todayDate = formatDateStringToMask(today.toISOString(), 'dd/MM/yyyy')

  const nameOfDay = format(today, 'EEEEEE', {
    locale: ptBR
  })

  const dayOfWeek = useMemo(
    () => daysOfWeek[nameOfDay as string as keyof typeof daysOfWeek],
    [nameOfDay]
  )

  const getRvData = isGoodsAndServices ? useGetIndicatorResumeQuery : useGetRvSummaryQuery

  const {
    data: rvIndicatorResume,
    isLoading: isLoadingRvIndicatorResume,
    refetch: refetchIndicatorResume
  } = getRvData()

  const rvIndicatorData = rvIndicatorResume?.data.data

  const {
    data: getAllNotifications,
    isLoading: newsLoading,
    refetch: refetchAllNotifications
  } = useGetAllNotificationsInfinityQuery({
    notification_type: NOTIFICATION_TYPES.COMMUNICATION
  })

  const newsData = useMemo(
    () =>
      getAllNotifications?.pages.reduce<TNotification[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data] as TNotification[]
      }, []),
    [getAllNotifications?.pages]
  )

  const handleGoToProfile = useCallback(
    () => navigation.navigate('Tab', { screen: 'Profile' }),
    [navigation]
  )

  const handleGoToOpportunitiesList = useCallback(
    (listType?: ListType) => {
      LogEvent(`tela_inicial_acessar_oportunidades`, {
        item_id: listType ? LIST_TYPES_NAMES[listType] : 'odc'
      })

      return isMobile
        ? navigation.navigate('Tab', {
            screen: 'Organization',
            params: { tabId: 2, listType }
          })
        : linkTo(`/minha-organizacao/oportunidades/${listType}`)
    },
    [navigation, isMobile, linkTo]
  )

  const {
    data: highlightedData,
    isLoading: highlightedIsLoading,
    isFetchingNextPage: highlightedIsFetchingNextPage,
    hasNextPage: highlightedHasNextPage,
    fetchNextPage: highlightedFetchNextPage,
    refetch: refetchKnowledgeBaseIsFeatured
  } = useGetKnowledgeBasesInfiniteQuery({
    is_featured: 1
  })

  const highlightedList = useMemo(() => mergeDataInfiniteQuery(highlightedData), [highlightedData])

  const onEndReachedHighlightedList = useCallback(() => {
    if (!highlightedIsFetchingNextPage && highlightedHasNextPage) {
      highlightedFetchNextPage()
    }
  }, [highlightedFetchNextPage, highlightedHasNextPage, highlightedIsFetchingNextPage])

  const handleGoToContentScreen = useCallback(
    (content: ContentSerialized) => linkTo(`/conteudos/${content.id}/detalhes`),
    [linkTo]
  )

  const [progress, setProgress] = useState<TArcProgressWalletProps>(
    setProgressValue({
      rv_simulation_goal_value: 0,
      rv_simulation_goal_points: 0,
      total_real_value: 0,
      total_real_points: 0,
      total_projection_value: 0,
      total_projection_points: 0,
      msg_awaiting_receive_full_rv_data: '',
      is_awaiting_receive_full_rv_data: false
    })
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      !!rvIndicatorData && setProgress(setProgressValue(rvIndicatorData as TIndicatorResume))
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [rvIndicatorData])

  const onRefresh = async () => {
    setRefreshing(true)

    setProgress(
      setProgressValue({
        rv_potentials: [],
        rv_simulation_goal_value: 0,
        rv_simulation_goal_points: 0,
        total_real_value: 0,
        total_real_points: 0,
        total_projection_value: 0,
        total_projection_points: 0,
        msg_awaiting_receive_full_rv_data: '',
        is_awaiting_receive_full_rv_data: false
      })
    )

    await refetchAllNotifications()

    await refetchIndicatorResume().then((res) => {
      const newRVIndicatorData = res.data?.data.data

      const timeout = setTimeout(() => {
        !!newRVIndicatorData &&
          setProgress(setProgressValue(newRVIndicatorData as TIndicatorResume))
      }, 600)

      return () => {
        clearTimeout(timeout)
      }
    })

    await refetchKnowledgeBaseIsFeatured()

    await refetchOpportunitiesSummary()

    await refetchProfile()

    setRefreshing(false)
  }

  const npsSheetRef = useRef<BottomSheetModal>(null)

  const openNpsSheet = useCallback(() => {
    npsSheetRef.current?.present()
  }, [])

  const closeNpsSheet = useCallback(() => {
    npsSheetRef.current?.close()

    npsSheetRef.current?.dismiss()
  }, [])

  useEffect(() => {
    if (dataNps?.data.data.formId) {
      const timeoutNPS = setTimeout(() => {
        npsSheetRef.current?.present()
      }, 3000)

      return () => {
        clearTimeout(timeoutNPS)
      }
    }
  }, [dataNps?.data.data.formId])

  const handleUpdateNps = useCallback(() => {
    updateNpsMutation(undefined, {
      onSuccess() {
        setTimeout(() => {
          closeNpsSheet()
        }, 3000)
      }
    })
  }, [closeNpsSheet, updateNpsMutation])

  useEffect(() => {
    const refetch = route.params?.refetch

    if (refetch) {
      refetchIndicatorResume()

      refetchOpportunitiesSummary()
    }
  }, [queryClient, refetchIndicatorResume, refetchOpportunitiesSummary, route])

  const projectedPotential = useMemo(
    () =>
      progress.potentials.filter(
        (potential) => potential.points === progress.projected.pointsAmount
      ),
    [progress.potentials, progress.projected.pointsAmount]
  )

  const handleOpenExtractRvRightSheet = () => {
    LogEvent(`extrato_da_rv`, {
      item_id: ''
    })

    navigation.navigate('SummaryDetail')
  }

  const {
    isOpen: calculatorIsOpen,
    onClose: calculatorOnClose,
    onOpen: calculatorOnOpen
  } = useDisclose(false)

  const {
    isOpen: calculatorOldIsOpen,
    onClose: calculatorOldOnClose,
    onOpen: calculatorOldOnOpen
  } = useDisclose(false)

  const { isOpen: dontShowConfirm, onToggle: handleDontShowConfirm } = useDisclose(true)

  const {
    isOpen: confirmIsOpen,
    onClose: confirmOnClose,
    onOpen: confirmOnOpen
  } = useDisclose(false)

  const handleOpenCalculatorOld = () => {
    LogEvent('ver_calculadora_rv_2024', {
      item_id: ''
    })

    return calculatorOldOnOpen()
  }

  const handleOpenCalculator = () => {
    LogEvent('ver_calculadora_rv', {
      item_id: ''
    })

    return calculatorOnOpen()
  }

  const handleCloseCalculator = () => {
    confirmOnClose()

    calculatorOnClose()

    calculatorOldOnClose()
  }

  const arcProgressData = useMemo(
    () => (isGoodsAndServices ? progress : (rvIndicatorData as RvSummary)),
    [isGoodsAndServices, progress, rvIndicatorData]
  )

  return {
    isMobile,
    newsData,
    onRefresh,
    refreshing,
    highlightedList,
    highlightedIsLoading,
    onEndReachedHighlightedList,
    highlightedIsFetchingNextPage,
    handleGoToContentScreen,
    handleGoToProfile,
    handleOpenExtractRvRightSheet,
    progress: arcProgressData,
    userName,
    imageProfile,
    todayDate,
    dayOfWeek,
    opportunitiesSummary,
    isLoadingOpportunitiesSummary,
    opportunitiesSummaryIsEmpty,
    isLoadingRvIndicatorResume,
    handleGoToOpportunitiesList,
    isLoadingProfile,
    initialLettersName: profile?.name,
    isGoodsAndServices,
    projectedPotential,
    npsSheetRef,
    openNpsSheet,
    closeNpsSheet,
    formId: dataNps?.data.data.formId,
    handleUpdateNps,
    userIsGR,
    handleOpenCalculator,
    calculatorIsOpen,
    calculatorOnClose,
    dontShowConfirm,
    handleDontShowConfirm,
    confirmIsOpen,
    confirmOnClose,
    confirmOnOpen,
    handleCloseCalculator,
    handleOpenCalculatorOld,
    calculatorOldIsOpen,
    calculatorOldOnClose,
    anyHookIsLoading:
      highlightedIsLoading ||
      isLoadingOpportunitiesSummary ||
      isLoadingRvIndicatorResume ||
      isLoadingProfile ||
      newsLoading
  }
}
