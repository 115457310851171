import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { RVCalculatorsOldProps } from './RVCalculatorsOld.types'
import { useRVCalculatorsOld } from './useRVCalculatorsOld'

const RVCalculatorsOldDesktop = lazy(() => import('./RVCalculatorsOld.desktop'))

export const RVCalculatorsOld: FunctionComponent<RVCalculatorsOldProps> = (props) => {
  const RVCalculatorsOld = useRVCalculatorsOld(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <RVCalculatorsOldDesktop {...RVCalculatorsOld} />
    </Suspense>
  )
}
