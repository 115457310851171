import { RevenueCalculatorFormFields } from './RevenueCalculator.types'

export const formsDefaultValues: RevenueCalculatorFormFields = {
  targetAccount: 0,
  weightProduction: 40,
  weightSPF: 20,
  weightSA: 10,
  weightCharge: 10,
  weightWebmotors: 10,
  weightEcosystem: 10,
  weightActiveBase: 50,
  weightMoreAccounts: 50,
  weightTotal: 100,
  targetProduction: 0,
  targetSPF: 0,
  targetSA: 0,
  targetCharge: 0,
  targetWebmotors: 0,
  targetEcosystem: 0,
  targetActiveBase: 0,
  targetMoreAccounts: 0,
  targetTotal: 0,
  goalProduction: 0,
  goalSPF: 0,
  goalSA: 0,
  goalCharge: 0,
  goalWebmotors: 0,
  goalEcosystem: 0,
  goalActiveBase: 0,
  goalMoreAccounts: 0,
  realizedProduction: 0,
  realizedSPF: 0,
  realizedSA: 0,
  realizedCharge: 0,
  realizedWebmotors: 0,
  realizedEcosystem: 0,
  realizedActiveBase: 0,
  realizedMoreAccounts: 0,
  percentRealizedProduction: 0,
  percentRealizedSPF: 0,
  percentRealizedSA: 0,
  percentRealizedCharge: 0,
  percentRealizedWebmotors: 0,
  percentRealizedEcosystem: 0,
  percentRealizedActiveBase: 0,
  percentRealizedMoreAccounts: 0,
  rvSimulatedProduction: 0,
  rvSimulatedSPF: 0,
  rvSimulatedSA: 0,
  rvSimulatedCharge: 0,
  rvSimulatedWebmotors: 0,
  rvSimulatedEcosystem: 0,
  rvSimulatedActiveBase: 0,
  rvSimulatedMoreAccounts: 0,
  rvSimulatedTotal: 0,
  chargeGoal: 1,
  expensesProduction: 0,
  expensesSPF: 0,
  expensesSA: 0,
  expensesCharge: 0,
  expensesWebmotors: 0,
  expensesEcosystem: 0,
  expensesActiveBase: 0,
  expensesMoreAccounts: 0,
  acceleratorRealizedProduction: 0,
  acceleratorRealizedSPF: 0,
  acceleratorRealizedSA: 0,
  acceleratorRealizedCharge: 0,
  acceleratorRealizedWebmotors: 0,
  acceleratorRealizedEcosystem: 0,
  acceleratorRealizedActiveBase: 0,
  acceleratorRealizedMoreAccounts: 0,
  rvAcceleratorProduction: 0,
  rvAcceleratorSPF: 0,
  rvAcceleratorSA: 0,
  rvAcceleratorCharge: 0,
  rvAcceleratorWebmotors: 0,
  rvAcceleratorEcosystem: 0,
  rvAcceleratorActiveBase: 0,
  rvAcceleratorMoreAccounts: 0,
  rvAcceleratorTotal: 0,
  multiplier: 0,
  multipliedRV: 0,
  performance: 0,
  rvTotal: 0
}

export const weightFields: (keyof RevenueCalculatorFormFields)[] = [
  'weightProduction',
  'weightSPF',
  'weightSA',
  'weightCharge',
  'weightWebmotors',
  'weightEcosystem',
  'weightActiveBase',
  'weightMoreAccounts'
]

export const targetFields: (keyof RevenueCalculatorFormFields)[] = [
  'targetProduction',
  'targetSPF',
  'targetSA',
  'targetCharge',
  'targetWebmotors',
  'targetEcosystem',
  'targetActiveBase',
  'targetMoreAccounts',
  'targetTotal'
]

export const goalFields: (keyof RevenueCalculatorFormFields)[] = [
  'goalProduction',
  'goalSPF',
  'goalSA',
  'goalCharge',
  'goalWebmotors',
  'goalEcosystem',
  'goalActiveBase',
  'goalMoreAccounts'
]

export const realizedFields: (keyof RevenueCalculatorFormFields)[] = [
  'realizedProduction',
  'realizedSPF',
  'realizedSA',
  'realizedCharge',
  'realizedWebmotors',
  'realizedEcosystem',
  'realizedActiveBase',
  'realizedMoreAccounts'
]

export const percentRealizedFields: (keyof RevenueCalculatorFormFields)[] = [
  'percentRealizedProduction',
  'percentRealizedSPF',
  'percentRealizedSA',
  'percentRealizedCharge',
  'percentRealizedWebmotors',
  'percentRealizedEcosystem',
  'percentRealizedActiveBase',
  'percentRealizedMoreAccounts'
]

export const rvSimulatedFields: (keyof RevenueCalculatorFormFields)[] = [
  'rvSimulatedProduction',
  'rvSimulatedSPF',
  'rvSimulatedSA',
  'rvSimulatedCharge',
  'rvSimulatedWebmotors',
  'rvSimulatedEcosystem',
  'rvSimulatedActiveBase',
  'rvSimulatedMoreAccounts',
  'rvSimulatedTotal'
]

export const acceleratorRealizedFields: (keyof RevenueCalculatorFormFields)[] = [
  'acceleratorRealizedProduction',
  'acceleratorRealizedSPF',
  'acceleratorRealizedSA',
  'acceleratorRealizedCharge',
  'acceleratorRealizedWebmotors',
  'acceleratorRealizedEcosystem',
  'acceleratorRealizedActiveBase',
  'acceleratorRealizedMoreAccounts'
]

export const rvAcceleratorFields: (keyof RevenueCalculatorFormFields)[] = [
  'rvAcceleratorProduction',
  'rvAcceleratorSPF',
  'rvAcceleratorSA',
  'rvAcceleratorCharge',
  'rvAcceleratorWebmotors',
  'rvAcceleratorEcosystem',
  'rvAcceleratorActiveBase',
  'rvAcceleratorMoreAccounts',
  'rvAcceleratorTotal'
]

export const expensesFields: (keyof RevenueCalculatorFormFields)[] = [
  'expensesProduction',
  'expensesSPF',
  'expensesSA',
  'expensesCharge',
  'expensesWebmotors',
  'expensesEcosystem',
  'expensesActiveBase',
  'expensesMoreAccounts'
]
