import { ExpoConfig } from '@expo/config-types'

const config: ExpoConfig = {
  scheme: 'portalmaisturbo',
  owner: 'laboritinc',
  name: 'Portal Mais Turbo',
  slug: 'portal-mais-turbo',
  version: '1.7.0',
  icon: './assets/icon.png',
  plugins: [
    [
      'expo-build-properties',
      {
        ios: {
          useFrameworks: 'static',
          deploymentTarget: '17.0'
        },
        android: {
          compileSdkVersion: 33,
          targetSdkVersion: 33,
          buildToolsVersion: '33.0.0',
          kotlinVersion: '1.7.10'
        }
      }
    ],
    [
      'expo-image-picker',
      {
        photosPermission: `Allow access to your device's photo library to select a picture for your profile.`,
        cameraPermission: `Allow access to your device's camera to take a picture for your profile.`
      }
    ],
    [
      'expo-document-picker',
      {
        iCloudContainerEnvironment: 'Production'
      }
    ],
    'expo-screen-orientation',
    'react-native-compressor',
    'sentry-expo',
    '@react-native-firebase/app',
    './plugins/withAndroidVerifiedLinksWorkaround'
  ],
  splash: {
    image: './assets/splash.png',
    resizeMode: 'cover',
    backgroundColor: '#ffffff'
  },
  updates: {
    fallbackToCacheTimeout: 0,
    url: 'https://u.expo.dev/6564321f-784e-456b-955c-8b679287e429'
  },
  assetBundlePatterns: ['**/*'],
  ios: {
    buildNumber: '170',
    supportsTablet: true,
    bundleIdentifier: 'com.webmotors.portalmaisturbo',
    associatedDomains: [
      'applinks:www.portalmaisturbo.com.br',
      'applinks:hml.portalmaisturbo.com.br'
    ],
    infoPlist: {
      NSCameraUsageDescription: `Allow access to your device's camera to take a picture for your profile.`,
      LSApplicationQueriesSchemes: ['lyft', 'whatsapp']
    },
    googleServicesFile: './GoogleService-Info.plist',
    config: {
      usesNonExemptEncryption: false
    }
  },
  notification: {
    icon: './assets/icon-notification.png',
    color: '#070707'
  },
  runtimeVersion: {
    policy: 'sdkVersion'
  },
  android: {
    versionCode: 170,
    googleServicesFile: './google-services.json',
    adaptiveIcon: {
      foregroundImage: './assets/adaptive-icon.png',
      backgroundColor: '#FFFFFF'
    },
    package: 'com.webmotors.portalmaisturbo',
    intentFilters: [
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: 'hml.portalmaisturbo.com.br'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      },
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: 'portalmaisturbo.com.br'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      },
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: '*.portalmaisturbo.com.br',
            pathPrefix: '/cadastro/email/confirmacao/processando'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      },
      {
        action: 'VIEW',
        autoVerify: true,
        data: [
          {
            scheme: 'https',
            host: '*.portalmaisturbo.com.br',
            pathPrefix: '/redefinir-senha/senha'
          }
        ],
        category: ['BROWSABLE', 'DEFAULT']
      }
    ]
  },
  web: {
    favicon: './assets/favicon.png',
    build: {
      babel: {
        include: ['victory-native']
      }
    }
  },
  extra: {
    eas: {
      projectId: '6564321f-784e-456b-955c-8b679287e429'
    }
  }
}

export default config
