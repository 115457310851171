import * as serializers from './serializers'
import {
  TRevenueCalculator,
  CALCULATOR_TYPE_ENUM,
  TCalculatorData,
  CalculatorParams
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getRevenueSimulationData = async () => {
  const response = await client.get<ResponseSuccess<TRevenueCalculator>>(
    'v1/variable-revenue/simulations'
  )

  return serializers.getRevenueData(response)
}

export const sendRevenueSimulation = async (data: TRevenueCalculator) => {
  const response = await client.post<ResponseSuccess<TRevenueCalculator>>(
    `v1/variable-revenue/simulations`,
    data
  )

  return response
}

export const resetRevenueSimulation = async () => {
  const response = await client.delete<ResponseSuccess<TRevenueCalculator>>(
    `v1/variable-revenue/simulations`
  )

  return response
}

// Other  calculators

export const getCalculatorData = async (
  calculatorType: CALCULATOR_TYPE_ENUM,
  year_month: number
) => {
  const response = await client.get<ResponseSuccess<TCalculatorData>>(
    `v1/variable-revenue/calculator/${calculatorType}/${year_month}`
  )

  return serializers.getCalculatorData(response)
}

export const sendCalculatorSimulation = async (data: TCalculatorData) => {
  const response = await client.post<ResponseSuccess<TCalculatorData>>(
    `v1/variable-revenue/calculator`,
    data
  )

  return response
}

export const resetCalculatorSimulation = async ({
  calculatorType,
  year_month
}: CalculatorParams) => {
  const response = await client.delete<ResponseSuccess<CalculatorParams>>(
    `v1/variable-revenue/calculator/${calculatorType}/${year_month}`
  )

  return response
}
