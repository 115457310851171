import {
  TParams,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  TOpenGoalsPeriodParams,
  TGoalsReport,
  TGoalsSummary,
  TGoalsLeadersListItem,
  TListsParams,
  TChangePeriodParams,
  TGoalsDetails
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

const USE_MOCK = true

const mockAxiosResponse = (data: any) => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
  request: {}
})

export const mockGoalsReport: TGoalsReport = {
  header: {
    leader_std_code: 12345,
    leader_name: 'João Silva',
    current_date: '2024-11-29T00:00:00.000Z',
    year_month: 202411,
    started_at: '2024-11-01T00:00:00.000Z',
    finished_at: '2024-11-30T23:59:59.000Z'
  },
  goal_adjustments: [
    {
      type: 'Seguro Prestamista',
      year_month: 202411,
      goal: 10000000.0,
      adjustments: [
        {
          consultant_name: 'Carlos Eduardo',
          role: 'Gerente de Vendas',
          consultant_id: '123456',
          reference: {
            year_month: 202411,
            sent_goal: 2000000.0,
            adjusted_goal: 2100000.0,
            variance: 5,
            adjustment_date: '2024-11-10T08:00:00.000Z',
            adjusted_by: 'Ana Clara'
          },
          history: {
            year_month: 202410,
            sent_goal: 1800000.0,
            adjusted_goal: 1890000.0,
            variance: 5,
            adjustment_date: '2024-10-12T08:00:00.000Z',
            adjusted_by: 'Ana Clara'
          }
        },
        {
          consultant_name: 'Paulo Santos',
          role: 'Consultor Comercial',
          consultant_id: '789101',
          reference: {
            year_month: 202411,
            sent_goal: 1500000.0,
            adjusted_goal: 1575000.0,
            variance: 5,
            adjustment_date: '2024-11-15T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          },
          history: {
            year_month: 202410,
            sent_goal: 1400000.0,
            adjusted_goal: 1470000.0,
            variance: 5,
            adjustment_date: '2024-10-16T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          }
        }
      ]
    },
    {
      type: 'Cobranças',
      year_month: 202411,
      goal: 8000000.0,
      adjustments: [
        {
          consultant_name: 'Mariana Almeida',
          role: 'Supervisora Regional',
          consultant_id: '654321',
          reference: {
            year_month: 202411,
            sent_goal: 1500000.0,
            adjusted_goal: 1575000.0,
            variance: 5,
            adjustment_date: '2024-11-15T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          },
          history: {
            year_month: 202410,
            sent_goal: 1400000.0,
            adjusted_goal: 1470000.0,
            variance: 5,
            adjustment_date: '2024-10-16T09:00:00.000Z',
            adjusted_by: 'Carlos Santos'
          }
        }
      ]
    },
    {
      type: 'Seguro Auto',
      year_month: 202411,
      goal: 12000000.0,
      adjustments: [
        {
          consultant_name: 'João Pereira',
          role: 'Consultor Comercial',
          consultant_id: '789012',
          reference: {
            year_month: 202411,
            sent_goal: 3000000.0,
            adjusted_goal: 3150000.0,
            variance: 5,
            adjustment_date: '2024-11-20T10:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          },
          history: {
            year_month: 202410,
            sent_goal: 2900000.0,
            adjusted_goal: 3045000.0,
            variance: 5,
            adjustment_date: '2024-10-22T10:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          }
        },
        {
          consultant_name: 'Fernanda Costa',
          role: 'Gerente de Contas',
          consultant_id: '654789',
          reference: {
            year_month: 202411,
            sent_goal: 1800000.0,
            adjusted_goal: 1890000.0,
            variance: 5,
            adjustment_date: '2024-11-25T08:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          },
          history: {
            year_month: 202410,
            sent_goal: 1700000.0,
            adjusted_goal: 1785000.0,
            variance: 5,
            adjustment_date: '2024-10-25T08:00:00.000Z',
            adjusted_by: 'Lucas Oliveira'
          }
        }
      ]
    },
    {
      type: 'Produção',
      year_month: 202411,
      goal: 20000000.0,
      adjustments: [
        {
          consultant_name: 'Ana Beatriz',
          role: 'Gerente Regional',
          consultant_id: '890123',
          reference: {
            year_month: 202411,
            sent_goal: 4000000.0,
            adjusted_goal: 4200000.0,
            variance: 5,
            adjustment_date: '2024-11-25T11:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 3800000.0,
            adjusted_goal: 3990000.0,
            variance: 5,
            adjustment_date: '2024-10-27T11:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        },
        {
          consultant_name: 'Cláudio Ribeiro',
          role: 'Consultor',
          consultant_id: '192837',
          reference: {
            year_month: 202411,
            sent_goal: 2000000.0,
            adjusted_goal: 2100000.0,
            variance: 5,
            adjustment_date: '2024-11-18T10:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 1900000.0,
            adjusted_goal: 1995000.0,
            variance: 5,
            adjustment_date: '2024-10-19T10:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        },
        {
          consultant_name: 'Marcela Souza',
          role: 'Consultora Comercial',
          consultant_id: '829384',
          reference: {
            year_month: 202411,
            sent_goal: 2200000.0,
            adjusted_goal: 2310000.0,
            variance: 5,
            adjustment_date: '2024-11-22T09:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          },
          history: {
            year_month: 202410,
            sent_goal: 2100000.0,
            adjusted_goal: 2205000.0,
            variance: 5,
            adjustment_date: '2024-10-22T09:00:00.000Z',
            adjusted_by: 'Fernando Silva'
          }
        }
      ]
    },
    {
      type: '+Contas',
      year_month: 202411,
      goal: 15000000.0,
      adjustments: [
        {
          consultant_name: 'Roberto Faria',
          role: 'Consultor Master',
          consultant_id: '567890',
          reference: {
            year_month: 202411,
            sent_goal: 2500000.0,
            adjusted_goal: 2625000.0,
            variance: 5,
            adjustment_date: '2024-11-28T12:00:00.000Z',
            adjusted_by: 'Patrícia Lima'
          },
          history: {
            year_month: 202410,
            sent_goal: 2400000.0,
            adjusted_goal: 2520000.0,
            variance: 5,
            adjustment_date: '2024-10-30T12:00:00.000Z',
            adjusted_by: 'Patrícia Lima'
          }
        }
      ]
    },
    {
      type: 'Webmotors',
      year_month: 202411,
      goal: 18000000.0,
      adjustments: [
        {
          consultant_name: 'Gabriela Nunes',
          role: 'Coordenadora Comercial',
          consultant_id: '345678',
          reference: {
            year_month: 202411,
            sent_goal: 5000000.0,
            adjusted_goal: 5250000.0,
            variance: 5,
            adjustment_date: '2024-11-29T13:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          },
          history: {
            year_month: 202410,
            sent_goal: 4800000.0,
            adjusted_goal: 5040000.0,
            variance: 5,
            adjustment_date: '2024-10-31T13:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          }
        },
        {
          consultant_name: 'Ricardo Lima',
          role: 'Gerente de Área',
          consultant_id: '918273',
          reference: {
            year_month: 202411,
            sent_goal: 4000000.0,
            adjusted_goal: 4200000.0,
            variance: 5,
            adjustment_date: '2024-11-25T14:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          },
          history: {
            year_month: 202410,
            sent_goal: 3900000.0,
            adjusted_goal: 4095000.0,
            variance: 5,
            adjustment_date: '2024-10-25T14:00:00.000Z',
            adjusted_by: 'Rafael Souza'
          }
        }
      ]
    }
  ]
}

export const mockedGoalsReportResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 33,
    requestId: 'clujrt5x100jtyjty0b1djtkbl0zyfk'
  },
  data: mockGoalsReport,
  status: 200
}

export const getGoalsSummary = async () =>
  await client.get<ResponseSuccess<TGoalsSummary>>(`v1/goals-management/summary`)

export const getGoalsLeadersList = async (params: TListsParams) => {
  const response = await client.get<ResponseSuccess<TGoalsLeadersListItem[]>>(
    `v1/goals-management/goals`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? undefined,
        order_by_direction: params.order_by_direction ?? 'desc',
        ...params
      }
    }
  )

  return response
}

export const openGoalsPeriod = async ({
  date_start,
  date_end,
  leader_std_code,
  business,
  goals
}: TOpenGoalsPeriodParams) => {
  return await client.post<ResponseSuccess<TOpenGoalsPeriodParams>>(`v1/goals-management/period`, {
    date_start,
    date_end,
    business,
    leader_std_code: leader_std_code ?? undefined,
    goals
  })
}

export const updateGoalsPeriodStatus = async ({
  periodId,
  status,
  leader_std_code
}: TChangePeriodParams) => {
  return await client.put<ResponseSuccess<TChangePeriodParams>>(
    `v1/goals-management/period/status`,
    {
      period_id: periodId,
      status,
      leader_std_code
    }
  )
}

export const getGoalsLeaderDetailsSummary = async (params: TParams) => {
  const response = await client.get<ResponseSuccess<TGoalsLeaderDetails>>(
    `v1/goals-management/summary/${params.leader_std_code ?? 0}`,
    {}
  )

  return response
}

export const getGoalsStaffList = async (params: TListsParams) => {
  const response = await client.get<ResponseSuccess<TGoalsStaffListItem[]>>(
    `v1/goals-management/goals/${params.leader_std_code}`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? undefined,
        order_by_direction: params.order_by_direction ?? 'desc',
        ...params
      }
    }
  )

  return response
}

export const getGoalsDetails = async (staffId: string) => {
  const response = await client.get<ResponseSuccess<TGoalsDetails>>(
    `v1/goals-management/goals/details/${staffId}`
  )

  return response
}

export const saveGoalsAdjustments = async ({ goals_adjustments }: TParams) =>
  await client.post<ResponseSuccess<TParams>>(`v1/goals-management/goals/details`, {
    ...goals_adjustments
  })

export const getGoalsReport = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsReportResponse))
  } else {
    return await client.get<ResponseSuccess<TGoalsReport>>(
      `v1/goals/reports/${params.leader_std_code}`,
      {}
    )
  }
}
