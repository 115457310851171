import { ResultsCalculatorFormFields } from './ResultsCalculator.types'

export const formsDefaultValues = {
  targetAccount: 0,
  weightProduction: 50,
  weightSPF: 20,
  weightSA: 10,
  weightCharge: 10,
  weightWebmotors: 10,
  weightTotal: 100,
  goalProduction: 0,
  goalSPF: 0,
  goalSA: 0,
  goalCharge: 0,
  goalWebmotors: 0,
  realizedProduction: 0,
  realizedSPF: 0,
  realizedSA: 0,
  realizedCharge: 0,
  realizedWebmotors: 0,
  percentAchievedProduction: 0,
  percentAchievedSPF: 0,
  percentAchievedSA: 0,
  percentAchievedCharge: 0,
  percentAchievedWebmotors: 0,
  goalOneProduction: 0,
  goalOneSPF: 0,
  goalOneSA: 0,
  goalOneCharge: 0,
  goalOneWebmotors: 0,
  realizedOneProduction: 0,
  realizedOneSPF: 0,
  realizedOneSA: 0,
  realizedOneCharge: 0,
  realizedOneWebmotors: 0,
  percentAchievedOneProduction: 0,
  percentAchievedOneSPF: 0,
  percentAchievedOneSA: 0,
  percentAchievedOneCharge: 0,
  percentAchievedOneWebmotors: 0,
  goalTwoProduction: 0,
  goalTwoSPF: 0,
  goalTwoSA: 0,
  goalTwoCharge: 0,
  goalTwoWebmotors: 0,
  realizedTwoProduction: 0,
  realizedTwoSPF: 0,
  realizedTwoSA: 0,
  realizedTwoCharge: 0,
  realizedTwoWebmotors: 0,
  percentAchievedTwoProduction: 0,
  percentAchievedTwoSPF: 0,
  percentAchievedTwoSA: 0,
  percentAchievedTwoCharge: 0,
  percentAchievedTwoWebmotors: 0,
  pointsProduction: 0,
  pointsSPF: 0,
  pointsSA: 0,
  pointsCharge: 0,
  pointsWebmotors: 0,
  acceleratedPointsProduction: 0,
  acceleratedPointsSPF: 0,
  acceleratedPointsSA: 0,
  acceleratedPointsCharge: 0,
  acceleratedPointsWebmotors: 0,
  acceleratedPointsTotal: 0,
  multiplier: 0,
  multipliedRV: 0,
  performance: 0,
  rvTotal: 0,
  rvValue: 0
}

export const weightFields: (keyof ResultsCalculatorFormFields)[] = [
  'weightProduction',
  'weightSPF',
  'weightSA',
  'weightCharge',
  'weightWebmotors'
]

export const goalOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalOneProduction',
  'goalOneSPF',
  'goalOneSA',
  'goalOneCharge',
  'goalOneWebmotors'
]

export const realizedOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedOneProduction',
  'realizedOneSPF',
  'realizedOneSA',
  'realizedOneCharge',
  'realizedOneWebmotors'
]

export const percentAchievedOneFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedOneProduction',
  'percentAchievedOneSPF',
  'percentAchievedOneSA',
  'percentAchievedOneCharge',
  'percentAchievedOneWebmotors'
]

export const goalTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalTwoProduction',
  'goalTwoSPF',
  'goalTwoSA',
  'goalTwoCharge',
  'goalTwoWebmotors'
]

export const realizedTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedTwoProduction',
  'realizedTwoSPF',
  'realizedTwoSA',
  'realizedTwoCharge',
  'realizedTwoWebmotors'
]

export const percentAchievedTwoFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedTwoProduction',
  'percentAchievedTwoSPF',
  'percentAchievedTwoSA',
  'percentAchievedTwoCharge',
  'percentAchievedTwoWebmotors'
]

export const goalFields: (keyof ResultsCalculatorFormFields)[] = [
  'goalProduction',
  'goalSPF',
  'goalSA',
  'goalCharge',
  'goalWebmotors'
]

export const realizedFields: (keyof ResultsCalculatorFormFields)[] = [
  'realizedProduction',
  'realizedSPF',
  'realizedSA',
  'realizedCharge',
  'realizedWebmotors'
]

export const percentAchievedFields: (keyof ResultsCalculatorFormFields)[] = [
  'percentAchievedProduction',
  'percentAchievedSPF',
  'percentAchievedSA',
  'percentAchievedCharge',
  'percentAchievedWebmotors'
]

export const pointsFields: (keyof ResultsCalculatorFormFields)[] = [
  'pointsProduction',
  'pointsSPF',
  'pointsSA',
  'pointsCharge',
  'pointsWebmotors'
]

export const acceleratedPointsFields: (keyof ResultsCalculatorFormFields)[] = [
  'acceleratedPointsProduction',
  'acceleratedPointsSPF',
  'acceleratedPointsSA',
  'acceleratedPointsCharge',
  'acceleratedPointsWebmotors',
  'acceleratedPointsTotal'
]
