/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseRVCalculatorsOld } from './RVCalculatorsOld.types'

export const useRVCalculatorsOld: UseRVCalculatorsOld = ({
  userIsGR,
  handleOpenCalculator,
  calculatorIsOpen,
  calculatorOnClose,
  dontShowConfirm,
  handleDontShowConfirm,
  confirmIsOpen,
  confirmOnClose,
  confirmOnOpen,
  handleCloseCalculator
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [currentTab, setCurrentTab] = useState<number>(0)

  return {
    isMobile,
    handleOpenCalculator,
    calculatorIsOpen,
    calculatorOnClose,
    dontShowConfirm,
    handleDontShowConfirm,
    confirmIsOpen,
    confirmOnClose,
    confirmOnOpen,
    handleCloseCalculator,
    currentTab,
    setCurrentTab,
    userIsGR
  }
}
