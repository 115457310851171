/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  goalFields,
  percentAchievedFields,
  earningsFields,
  realizedFields,
  targetFields,
  weightFields,
  goalOneFields,
  goalTwoFields,
  percentAchievedOneFields,
  percentAchievedTwoFields,
  realizedOneFields,
  realizedTwoFields,
  formsDefaultValues
} from './constants'
import {
  RecoveryCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseRecoveryCalculator
} from './RecoveryCalculator.types'
import {
  calculatePercentRealized,
  calculateTarget,
  calculateTotal
} from '../../Utils/calculationsFunctions'
import { indicatorsLines, targets } from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof RecoveryCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// Validation schema creation for RV calculator form fields
const RecoveryCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...targetFields,
  ...goalOneFields,
  ...realizedOneFields,
  ...percentAchievedOneFields,
  ...goalTwoFields,
  ...realizedTwoFields,
  ...percentAchievedTwoFields,
  ...goalFields,
  ...realizedFields,
  ...percentAchievedFields,
  ...earningsFields,
  'targetAccount',
  'targetTotal',
  'weightTotal',
  'earningsTotal'
])

// Returns the first three targets for GR users (userIsGR is true), or full targets otherwise.
const getFilteredTargets = (targets: SelectedTarget[], userIsGR?: boolean) =>
  userIsGR ? targets.slice(0, 6) : targets

export const useRecoveryCalculator: UseRecoveryCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: showTooltip, onOpen: openTooltip, onClose: closeTooltip } = useDisclose(false)

  const year_month = 202412

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<RecoveryCalculatorFormFields>({
    resolver: yupResolver(RecoveryCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const defaultTargetAccount = targets[userIsGR ? 0 : 6]

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(defaultTargetAccount)

  const { data: recoveryData, isLoading: isLoadingRecoveryData } = useGetCalculatorData(
    CALCULATOR_TYPE_ENUM.RECOVERY,
    year_month,
    true
  )

  useEffect(() => {
    if (recoveryData?.data?.data) {
      reset({ ...recoveryData.data.data })
    }
  }, [recoveryData, reset])

  const { mutateAsync, isLoading: isSendingSimulation } = useSendCalculatorSimulationMutation(
    CALCULATOR_TYPE_ENUM.RECOVERY,
    year_month
  )

  const { mutate: resetRecoverySimulation, isLoading: isResettingRecoverySimulation } =
    useResetCalculatorSimulationMutation({
      calculatorType: CALCULATOR_TYPE_ENUM.RECOVERY,
      year_month
    })

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<RecoveryCalculatorFormFields>>(
      async (formData) =>
        mutateAsync(
          {
            calculator_json: formData,
            year_month: 202412,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.RECOVERY
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formFields)

          savedOnOpen()
        }),

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetRecoverySimulation(
      { calculatorType: CALCULATOR_TYPE_ENUM.RECOVERY, year_month },
      {
        onSuccess: () => {
          setSelectedTarget(targets[userIsGR ? 0 : 6])

          reset(formsDefaultValues)
        }
      }
    )
  }

  // --- isFulfilled Section ---
  // Callback to check if a specific field is fulfilled (>= 100)

  const [linesFulfilled, setLinesFulfilled] = useState(0)

  const isFulfilled = useCallback(
    (fieldName: keyof RecoveryCalculatorFormFields) => {
      return formFields[fieldName] >= 100
    },
    [formFields]
  )

  useEffect(() => {
    const fulfilledLinesCount = percentAchievedFields.filter((field) => isFulfilled(field)).length

    setLinesFulfilled(fulfilledLinesCount)
  }, [...percentAchievedFields.map((field) => formFields[field]), isFulfilled])

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightSPF,
      formFields.weightSA,
      formFields.weightCharge,
      formFields.weightWebmotors,
      formFields.weightMoreAccounts
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightWebmotors,
    formFields.weightCharge,
    formFields.weightMoreAccounts,
    formFields.weightProduction,
    formFields.weightSA,
    formFields.weightSPF
  ])

  // --- Target Section ---
  // Function to update target values for each field
  const updateTargetValues = () => {
    setValue(
      'targetProduction',
      calculateTarget(formFields.weightProduction, formFields.targetAccount)
    )

    setValue('targetSPF', calculateTarget(formFields.weightSPF, formFields.targetAccount))

    setValue('targetSA', calculateTarget(formFields.weightSA, formFields.targetAccount))

    setValue('targetCharge', calculateTarget(formFields.weightCharge, formFields.targetAccount))

    setValue(
      'targetWebmotors',
      calculateTarget(formFields.weightWebmotors, formFields.targetAccount)
    )

    setValue(
      'targetMoreAccounts',
      calculateTarget(formFields.weightMoreAccounts, formFields.targetAccount)
    )

    const {
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetMoreAccounts
    } = getValues()

    const values = [
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetMoreAccounts
    ]

    return setValue('targetTotal', calculateTotal(values))
  }

  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(defaultTargetAccount)

      setValue('targetAccount', defaultTargetAccount.value)
    }
  }, [selectedTarget, formFields.targetAccount])

  useEffect(() => {
    if (formFields.weightTotal === 100) updateTargetValues()
  }, [formFields.weightTotal, formFields.targetAccount])

  // --- PercentAchieved Section ---
  // Function to update percent realized value for a given field
  const updatePercentAchievedValue = (
    name: keyof RecoveryCalculatorFormFields,
    goal: number,
    realized: number
  ) => {
    return setValue(name, calculatePercentRealized(goal, realized, name.includes('Production')))
  }

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneProduction',
      formFields.goalOneProduction,
      formFields.realizedOneProduction
    )
  }, [formFields.goalOneProduction, formFields.realizedOneProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneSPF',
      formFields.goalOneSPF,
      formFields.realizedOneSPF
    )
  }, [formFields.goalOneSPF, formFields.realizedOneSPF])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneSA',
      formFields.goalOneSA,
      formFields.realizedOneSA
    )
  }, [formFields.goalOneSA, formFields.realizedOneSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneCharge',
      formFields.goalOneCharge,
      formFields.realizedOneCharge
    )
  }, [formFields.goalOneCharge, formFields.realizedOneCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneWebmotors',
      formFields.goalOneWebmotors,
      formFields.realizedOneWebmotors
    )
  }, [formFields.goalOneWebmotors, formFields.realizedOneWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneMoreAccounts',
      formFields.goalOneMoreAccounts,
      formFields.realizedOneMoreAccounts
    )
  }, [formFields.goalOneMoreAccounts, formFields.realizedOneMoreAccounts])

  // --- update percentAchieved Two Section ---

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoProduction',
      formFields.goalTwoProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.goalTwoProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoSPF',
      formFields.goalTwoSPF,
      formFields.realizedTwoSPF
    )
  }, [formFields.goalTwoSPF, formFields.realizedTwoSPF])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoSA',
      formFields.goalTwoSA,
      formFields.realizedTwoSA
    )
  }, [formFields.goalTwoSA, formFields.realizedTwoSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoCharge',
      formFields.goalTwoCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.goalTwoCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoWebmotors',
      formFields.goalTwoWebmotors,
      formFields.realizedTwoWebmotors
    )
  }, [formFields.goalTwoWebmotors, formFields.realizedTwoWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoMoreAccounts',
      formFields.goalTwoMoreAccounts,
      formFields.realizedTwoMoreAccounts
    )
  }, [formFields.goalTwoMoreAccounts, formFields.realizedTwoMoreAccounts])

  // --- update goal & realized Section ---

  const updateGoalOrRealized = (
    fieldName: keyof RecoveryCalculatorFormFields,
    valueOne: number,
    valueTwo: number
  ) => {
    setValue(fieldName, calculateTotal([valueOne, valueTwo]))
  }

  useEffect(() => {
    updateGoalOrRealized(
      'goalProduction',
      formFields.goalOneProduction,
      formFields.goalTwoProduction
    )
  }, [formFields.goalOneProduction, formFields.goalTwoProduction])

  useEffect(() => {
    updateGoalOrRealized('goalSPF', formFields.goalOneSPF, formFields.goalTwoSPF)
  }, [formFields.goalOneSPF, formFields.goalTwoSPF])

  useEffect(() => {
    updateGoalOrRealized('goalSA', formFields.goalOneSA, formFields.goalTwoSA)
  }, [formFields.goalOneSA, formFields.goalTwoSA])

  useEffect(() => {
    updateGoalOrRealized('goalCharge', formFields.goalOneCharge, formFields.goalTwoCharge)
  }, [formFields.goalOneCharge, formFields.goalTwoCharge])

  useEffect(() => {
    updateGoalOrRealized('goalWebmotors', formFields.goalOneWebmotors, formFields.goalTwoWebmotors)
  }, [formFields.goalOneWebmotors, formFields.goalTwoWebmotors])

  useEffect(() => {
    updateGoalOrRealized(
      'goalMoreAccounts',
      formFields.goalOneMoreAccounts,
      formFields.goalTwoMoreAccounts
    )
  }, [formFields.goalOneMoreAccounts, formFields.goalTwoMoreAccounts])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedProduction',
      formFields.realizedOneProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.realizedOneProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updateGoalOrRealized('realizedSPF', formFields.realizedOneSPF, formFields.realizedTwoSPF)
  }, [formFields.realizedOneSPF, formFields.realizedTwoSPF])

  useEffect(() => {
    updateGoalOrRealized('realizedSA', formFields.realizedOneSA, formFields.realizedTwoSA)
  }, [formFields.realizedOneSA, formFields.realizedTwoSA])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedCharge',
      formFields.realizedOneCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.realizedOneCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedWebmotors',
      formFields.realizedOneWebmotors,
      formFields.realizedTwoWebmotors
    )
  }, [formFields.realizedOneWebmotors, formFields.realizedTwoWebmotors])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedMoreAccounts',
      formFields.realizedOneMoreAccounts,
      formFields.realizedTwoMoreAccounts
    )
  }, [formFields.realizedOneMoreAccounts, formFields.realizedTwoMoreAccounts])

  // --- updatePercentAchievedValue Section ---

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentAchievedValue('percentAchievedSPF', formFields.goalSPF, formFields.realizedSPF)
  }, [formFields.goalSPF, formFields.realizedSPF])

  useEffect(() => {
    updatePercentAchievedValue('percentAchievedSA', formFields.goalSA, formFields.realizedSA)
  }, [formFields.goalSA, formFields.realizedSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedCharge',
      formFields.goalCharge,
      formFields.realizedCharge
    )
  }, [formFields.goalCharge, formFields.realizedCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedWebmotors',
      formFields.goalWebmotors,
      formFields.realizedWebmotors
    )
  }, [formFields.goalWebmotors, formFields.realizedWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedMoreAccounts',
      formFields.goalMoreAccounts,
      formFields.realizedMoreAccounts
    )
  }, [formFields.goalMoreAccounts, formFields.realizedMoreAccounts])

  // --- updateEarnings Section ---

  const updateEarnings = (
    earningField: keyof RecoveryCalculatorFormFields,
    target: number,
    percentOne: number,
    percentTwo: number,
    percentFinal: number
  ) => {
    const firstValid = percentOne >= 100

    const secondValid = percentTwo >= 100

    let newEarning = 0

    if ((firstValid || secondValid) && percentFinal >= 100 && !(firstValid && secondValid)) {
      newEarning = target
    }

    return setValue(earningField, newEarning)
  }

  useEffect(() => {
    updateEarnings(
      'earningsProduction',
      formFields.targetProduction,
      formFields.percentAchievedOneProduction,
      formFields.percentAchievedTwoProduction,
      formFields.percentAchievedProduction
    )
  }, [
    formFields.targetProduction,
    formFields.percentAchievedOneProduction,
    formFields.percentAchievedTwoProduction,
    formFields.percentAchievedProduction
  ])

  useEffect(() => {
    updateEarnings(
      'earningsSPF',
      formFields.targetSPF,
      formFields.percentAchievedOneSPF,
      formFields.percentAchievedTwoSPF,
      formFields.percentAchievedSPF
    )
  }, [
    formFields.targetSPF,
    formFields.percentAchievedOneSPF,
    formFields.percentAchievedTwoSPF,
    formFields.percentAchievedSPF
  ])

  useEffect(() => {
    updateEarnings(
      'earningsSA',
      formFields.targetSA,
      formFields.percentAchievedOneSA,
      formFields.percentAchievedTwoSA,
      formFields.percentAchievedSA
    )
  }, [
    formFields.targetSA,
    formFields.percentAchievedOneSA,
    formFields.percentAchievedTwoSA,
    formFields.percentAchievedSA
  ])

  useEffect(() => {
    updateEarnings(
      'earningsCharge',
      formFields.targetCharge,
      formFields.percentAchievedOneCharge,
      formFields.percentAchievedTwoCharge,
      formFields.percentAchievedCharge
    )
  }, [
    formFields.targetCharge,
    formFields.percentAchievedOneCharge,
    formFields.percentAchievedTwoCharge,
    formFields.percentAchievedCharge
  ])

  useEffect(() => {
    updateEarnings(
      'earningsWebmotors',
      formFields.targetWebmotors,
      formFields.percentAchievedOneWebmotors,
      formFields.percentAchievedTwoWebmotors,
      formFields.percentAchievedWebmotors
    )
  }, [
    formFields.targetWebmotors,
    formFields.percentAchievedOneWebmotors,
    formFields.percentAchievedTwoWebmotors,
    formFields.percentAchievedWebmotors
  ])

  useEffect(() => {
    updateEarnings(
      'earningsMoreAccounts',
      formFields.targetMoreAccounts,
      formFields.percentAchievedOneMoreAccounts,
      formFields.percentAchievedTwoMoreAccounts,
      formFields.percentAchievedMoreAccounts
    )
  }, [
    formFields.targetMoreAccounts,
    formFields.percentAchievedOneMoreAccounts,
    formFields.percentAchievedTwoMoreAccounts,
    formFields.percentAchievedMoreAccounts
  ])

  // --- earningsTotal Section ---

  const updateTotalEarnings = () => {
    const earningsValues = [
      formFields.earningsProduction,
      formFields.earningsSPF,
      formFields.earningsSA,
      formFields.earningsCharge,
      formFields.earningsWebmotors,
      formFields.earningsMoreAccounts
    ]

    const total = calculateTotal(earningsValues)

    let newTotal = 0

    if (linesFulfilled >= 2) newTotal = total

    setValue('earningsTotal', newTotal)
  }

  useEffect(() => {
    updateTotalEarnings()
  }, [
    formFields.earningsWebmotors,
    formFields.earningsCharge,
    formFields.earningsMoreAccounts,
    formFields.earningsProduction,
    formFields.earningsSA,
    formFields.earningsSPF,
    linesFulfilled
  ])

  const filteredTargets = useMemo(() => getFilteredTargets(targets, userIsGR), [targets, userIsGR])

  return {
    isMobile,
    userIsGR,

    control,
    errors,
    isDirty,

    targets: filteredTargets,
    selectedTarget,
    setSelectedTarget,

    openTooltip,
    closeTooltip,
    showTooltip,

    handleConfirmSimulation,
    handleResetSimulation,

    indicatorsLines,
    goalFields,
    percentAchievedFields,
    earningsFields,
    realizedFields,
    targetFields,
    weightFields,
    goalOneFields,
    goalTwoFields,
    percentAchievedOneFields,
    percentAchievedTwoFields,
    realizedOneFields,
    realizedTwoFields,

    isLoading: isLoadingRecoveryData,
    isSending: isSendingSimulation || isResettingRecoverySimulation,

    successSave,
    savedIsOpen,
    savedOnClose
  }
}
