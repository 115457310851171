import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { format, parseISO } from 'date-fns'
import { useCreateActivityMutation } from 'integration/resources/activities'
import { useGetTabItemQuery } from 'integration/resources/wallet'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { CheckItem } from 'organisms/Checklist/types'
import { useForm } from 'react-hook-form'
import {
  ActivityForm,
  activityFormSchema
} from 'src/components/templates/ActivityFormTemplate/types'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

import { UseCreateActivity } from './types'
import { serializerActivity } from '../serializers'

export const useCreateActivity: UseCreateActivity = ({ route, navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const toast = useToast()

  const createActivityMutation = useCreateActivityMutation()

  const { refetch } = useGetTabItemQuery(String(route?.params?.store?.tab_number) ?? '')

  const onHide = () => navigation.goBack()

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit: onSubmit
  } = useForm<ActivityForm>({
    mode: 'onSubmit',
    resolver: yupResolver(activityFormSchema),
    defaultValues: {
      activity_plan_action_items: [],
      activity_attachments: [],
      is_all_day_schedule: false,
      schedule_date_start: undefined,
      schedule_date_end: format(new Date(), 'yyyy-MM-dd'),
      schedule_date: format(new Date(), 'yyyy-MM-dd')
    }
  })

  const handleSubmit = (formData: ActivityForm) => {
    const values = {
      ...formData,
      schedule_date_start: formData.schedule_date_start
        ? String(parseISO(formData.schedule_date_start))
        : undefined,
      schedule_date_end: formData.schedule_date_end
        ? String(parseISO(formData.schedule_date_end))
        : String(new Date()),
      schedule_date: formData.schedule_date_end
        ? String(parseISO(formData.schedule_date_end))
        : String(new Date())
    }

    createActivityMutation.mutate(
      serializerActivity(values, authAtom?.user.std_code ?? values.created_by_user_std_code),
      {
        onError: () => {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            duration: 3000
          })
        },
        onSuccess: async (response) => {
          reset({})

          if (route.params?.id) {
            LogEvent('atividade_criada_a_partir_oportunidade', {
              item_id: route.params?.id
            })
          } else {
            LogEvent('atividade_criada', {
              item_id: response.data.data.id
            })
          }

          navigation.goBack()

          toast.show({
            render: () => <Toast type="success" text="Tarefa criada com sucesso 🎉" />,
            duration: 3000
          })
        }
      }
    )
  }

  const handleAddItemActionPlan = (value: string) => {
    setValue('activity_plan_action_items', [
      ...(getValues('activity_plan_action_items') ? getValues('activity_plan_action_items') : []),
      {
        value,
        id: value,
        checked: false
      }
    ])
  }

  const handleUpdateItemActionPlan = (item: CheckItem, checked: boolean) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').map((obj) => {
        if (obj.id === item.id) {
          return { ...obj, checked }
        }

        return obj
      })
    )
  }

  const handleRemoveItemActionPlan = (item: CheckItem) => {
    setValue(
      'activity_plan_action_items',
      getValues('activity_plan_action_items').filter((obj) => obj.id !== item.id)
    )
  }

  useEffect(() => {
    if (route.params?.store) {
      refetch().then(({ data: storeData }) => {
        const storeDetails = storeData?.data.data

        reset({
          store: {
            id: storeDetails?.id,
            identifier: storeDetails?.identifier,
            store_wallet_identifier: storeDetails?.identifier,
            tab_number: storeDetails?.tab_number,
            tab: Number(storeDetails?.identifier?.slice(0, 6)),
            name_fantasy: storeDetails?.name ?? storeDetails?.store_name
          }
        })
      })
    }
  }, [route.params?.store, reset, refetch])

  useEffect(() => {
    if (!route.params?.opportunity_id) {
      const data: any = {}

      if (route.params?.title) {
        data.title = route.params.title
      }

      if (route.params?.date_end) {
        data.schedule_date_start = route.params.date_start

        data.schedule_date_end = route.params.date_end
      }

      reset(data)
    }
  }, [route.params, reset])

  return {
    watch,
    setValue,
    control,
    isMobile,
    onSubmit,
    buttonIsLoading: createActivityMutation.isLoading,
    handleSubmit,
    handleAddItemActionPlan,
    handleUpdateItemActionPlan,
    handleRemoveItemActionPlan,
    onHide
  }
}
