import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AddIcon: FunctionComponent<IIconProps & { outlined?: boolean }> = ({
  outlined,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {outlined ? (
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.8C2.5 3.70132 4.20132 2 6.3 2H18.3C20.3987 2 22.1 3.70132 22.1 5.8V17.8C22.1 19.8987 20.3987 21.6 18.3 21.6H6.3C4.20132 21.6 2.5 19.8987 2.5 17.8V5.8ZM6.3 0C3.09675 0 0.5 2.59675 0.5 5.8V17.8C0.5 21.0033 3.09675 23.6 6.3 23.6H18.3C21.5033 23.6 24.1 21.0033 24.1 17.8V5.8C24.1 2.59675 21.5033 0 18.3 0H6.3ZM12.2998 6C12.8521 6 13.2998 6.44772 13.2998 7V10.8H17.1C17.6523 10.8 18.1 11.2477 18.1 11.8C18.1 12.3523 17.6523 12.8 17.1 12.8H13.2998V16.6C13.2998 17.1523 12.8521 17.6 12.2998 17.6C11.7475 17.6 11.2998 17.1523 11.2998 16.6V12.8H7.5C6.94772 12.8 6.5 12.3523 6.5 11.8C6.5 11.2477 6.94772 10.8 7.5 10.8H11.2998V7C11.2998 6.44772 11.7475 6 12.2998 6Z"
        fill="currentColor"
      />
    ) : (
      <Path
        d="M2.07 11.07a1 1 0 1 0 0 2h9v9a1 1 0 1 0 2 0v-9h9a1 1 0 0 0 0-2h-9v-9a1 1 0 0 0-2 0v9h-9Z"
        fill="currentColor"
      />
    )}
  </Icon>
)
