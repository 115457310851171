import { useCallback, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import {
  TGoalsStaffListItem,
  useGetGoalsStaffListInfinityQuery,
  useGetGoalsLeaderDetailsSummaryQuery,
  IndividualPeriodStatus,
  useGetGoalsReportQuery,
  MetaType,
  useUpdateGoalsPeriodMutation
} from 'integration/resources/goals'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { OrderField, UseGoalsLeaderDetailsScreen } from './GoalsLeaderDetailsScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

const mergeGoalsStaffInfiniteQuery = (
  data: ReturnType<typeof useGetGoalsStaffListInfinityQuery>['data']
) =>
  data?.pages.reduce<TGoalsStaffListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Valor da meta',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

export const useGoalsLeaderDetailsScreen: UseGoalsLeaderDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const leaderStdCode = route?.params?.userStdCode

  const authAtomValue = useAuthAtomValue()

  const isAdmin = useMemo(
    () => !!(authAtomValue?.user?.is_admin && leaderStdCode),
    [authAtomValue?.user?.is_admin, leaderStdCode]
  )

  const linkTo = useLinkTo()

  const handleGoBack = () => linkTo('/metas-admin')

  const userStdCode = useMemo(
    () => authAtomValue?.user?.std_code ?? 0,
    [authAtomValue?.user?.std_code]
  )

  const { data: leaderDetailsData, isLoading: leaderDetailsIsLoading } =
    useGetGoalsLeaderDetailsSummaryQuery({
      leader_std_code: leaderStdCode ?? String(userStdCode)
    })

  const detailsData = leaderDetailsData?.data.data

  const [currentList, setCurrentList] = useState<MetaType>(MetaType.Production)

  const handleListChange = (list: MetaType) => {
    setCurrentList(list)
  }

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const [appliedOrderBy, setAppliedOrderBy] = useState<'user_name' | 'meta_value' | undefined>()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'user_name'
          : 'meta_value'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const {
    data: staffListData,
    isLoading: staffListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetGoalsStaffListInfinityQuery({
    leader_std_code: leaderStdCode ?? String(userStdCode),
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    meta_type: currentList,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const staffList = useMemo(() => mergeGoalsStaffInfiniteQuery(staffListData), [staffListData])

  const staffListTotalItems = staffList.length

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const handleGoToOpenGoalsPeriod = useCallback(() => {
    navigation.navigate('OpenGoalsPeriod', {
      leaderStdCode: String(leaderStdCode)
    })
  }, [navigation, leaderStdCode])

  const {
    isOpen: reactivateModalIsOpen,
    onClose: reactivateModalOnClose,
    onOpen: reactivateModalOnOpen
  } = useDisclose()

  const { mutateAsync: updatePeriod, isLoading: updatePeriodIsLoading } =
    useUpdateGoalsPeriodMutation()

  const handleReactivate = useCallback(() => {
    detailsData?.id &&
      updatePeriod(
        {
          periodId: detailsData?.id,
          status: IndividualPeriodStatus.REACTIVATED,
          leader_std_code: Number(leaderStdCode ?? 0)
        },
        {
          onError: () => {
            reactivateModalOnClose()
          },
          onSuccess: () => {
            reactivateModalOnClose()
          }
        }
      )
  }, [reactivateModalOnClose, detailsData?.id, updatePeriod, leaderStdCode])

  const {
    isOpen: concludeModalIsOpen,
    onClose: concludeModalOnClose,
    onOpen: concludeModalOnOpen
  } = useDisclose()

  const handleConclude = useCallback(() => {
    detailsData?.id &&
      updatePeriod(
        {
          periodId: detailsData?.id,
          status: IndividualPeriodStatus.COMPLETED,
          leader_std_code: Number(leaderStdCode)
        },
        {
          onError: () => {
            concludeModalOnClose()
          },
          onSuccess: () => {
            concludeModalOnClose()
          }
        }
      )
  }, [concludeModalOnClose, detailsData?.id, updatePeriod, leaderStdCode])

  const { data: goalsReportData, isLoading: goalsReportIsLoading } = useGetGoalsReportQuery({
    leader_std_code: Number(leaderStdCode),
    enabled: true
  })

  //@ts-ignore
  const reportData = goalsReportData?.data.data

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleDownloadReport = useCallback(async () => {
    setDownloadReportIsLoading(true)

    try {
      const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

      const file = blob

      saveAs(file, 'Relatorio Ajuste de Metas.pdf')
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error)
    }

    setDownloadReportIsLoading(false)
  }, [reportData])

  const {
    isOpen: goalAdjustmentModalIsOpen,
    onClose: goalAdjustmentModalOnClose,
    onOpen: goalAdjustmentModalOnOpen
  } = useDisclose()

  const [adjustmentStaffInfos, setAdjustmentStaffInfos] = useState<{
    name: string
    occupation: string
  }>()

  const handleOpenGoalAdjustment = (staffId: string, name: string, occupation: string) => {
    navigation.navigate('GoalsAdjustment', {
      staffId: String(staffId),
      infos: {
        name,
        occupation
      },
      currentList
    })

    setAdjustmentStaffInfos({
      name,
      occupation
    })

    goalAdjustmentModalOnOpen()
  }

  const [openedTooltip, setOpenedTooltip] = useState<string | undefined>()

  const handleOpenTooltip = (title?: string) => {
    setOpenedTooltip(title)
  }

  const anyHookIsLoading =
    leaderDetailsIsLoading ||
    staffListLoading ||
    downloadReportIsLoading ||
    goalsReportIsLoading ||
    updatePeriodIsLoading

  const deltasIsOk =
    Object.entries(detailsData?.deltas ?? {}).filter(([key, value]) => Number(value) !== 0)
      .length === 0

  const periodIsOpen =
    detailsData?.status === IndividualPeriodStatus.OPENED ||
    detailsData?.status === IndividualPeriodStatus.REACTIVATED

  return {
    isMobile,
    isAdmin,
    handleGoBack,
    detailsData,
    leaderDetailsIsLoading,
    currentList,
    handleListChange,
    staffListData: staffList,
    staffListIsLoading: staffListLoading,
    staffListIsFetchingNextPage: isFetchingNextPage,
    staffListTotalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToOpenGoalsPeriod,
    reactivateModalIsOpen,
    reactivateModalOnClose,
    reactivateModalOnOpen,
    handleReactivate,
    anyHookIsLoading,
    handleDownloadReport,
    downloadReportIsLoading,
    concludeModalIsOpen,
    concludeModalOnClose,
    concludeModalOnOpen,
    handleConclude,
    goalAdjustmentModalIsOpen,
    goalAdjustmentModalOnClose,
    handleOpenGoalAdjustment,
    openedTooltip,
    handleOpenTooltip,
    adjustmentStaffInfos,
    updatePeriodIsLoading,
    deltasIsOk,
    periodIsOpen
  }
}
