import { multiplierValues, resultMultiplierValues } from './constants'

export const calculateTotal = (values: number[]) =>
  parseFloat(
    values
      .reduce((acc, curr) => {
        return acc + curr
      }, 0)
      .toFixed(2)
  )

export const calculateTarget = (weight: number, targetAccount: number) =>
  (targetAccount * weight) / 100

export const calculatePercentRealized = (
  goal: number,
  realized: number,
  isProduction: boolean = false
) => {
  const percent = (realized / goal) * 100

  const maxPercent = isProduction ? 200 : 150

  const percentLimited = percent > maxPercent ? maxPercent : percent

  return isNaN(percentLimited) ? 0 : percentLimited
}

export const calculateRVSimulated = (target: number, realized: number) =>
  parseFloat(((target * realized) / 100).toFixed(2))

export const calculateAcceleratorRealized = (
  realized: number,
  accelerators: number,
  isProduction: boolean = false,
  goal?: number
) => {
  if (goal === 2) return 150 + accelerators

  const percent = realized + accelerators

  const maxPercent = isProduction ? 200 : 150

  return percent > maxPercent ? maxPercent : percent
}

export const calculateRVMultiplied = (multiplier: number, rv: number) => rv * multiplier

export const calculateWeightedAverage = (
  weightOne: number,
  valueOne: number,
  weightTwo: number,
  valueTwo: number
) => {
  const totalWeight = weightOne + weightTwo

  const weightedSum = weightOne * valueOne + weightTwo * valueTwo

  const weightedAverage = weightedSum / totalWeight

  return weightedAverage >= 100
}

export const calculateWeightedAverageResults = (
  weightOne: number,
  valueOne: number,
  weightTwo: number,
  valueTwo: number
) => {
  const totalWeight = weightOne + weightTwo

  const weightedSum = valueOne + valueTwo

  const weightedAverage = weightedSum / totalWeight

  return weightedAverage >= 1
}

export const calculateMultiplier = (linesFulfilled: number, containsInsurance: boolean) => {
  const multiplier = multiplierValues[linesFulfilled]
    ? containsInsurance
      ? multiplierValues[linesFulfilled].withInsurance
      : multiplierValues[linesFulfilled].withoutInsurance
    : 0

  return multiplier
}

export const calculateResultsMultiplier = (quadrant: number, containsInsurance: boolean) => {
  const multiplier = resultMultiplierValues[quadrant]
    ? containsInsurance
      ? resultMultiplierValues[quadrant].withInsurance
      : resultMultiplierValues[quadrant].withoutInsurance
    : 0

  return multiplier
}

export const calculateTotalWithPercentage = (value: number, percentage: number) =>
  value + value * (percentage / 100)

export const calculateWeightedMean = (values: number[], weights: number[]) => {
  const totalWeight = weights.reduce((acc, weight) => acc + weight, 0)

  const weightedSum = values.reduce((acc, value, index) => acc + value * weights[index], 0)

  const result = weightedSum / totalWeight

  return result
}
