import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { GoalsAdjustmentScreenComponent } from './GoalsAdjustmentScreen.types'
import { useGoalsAdjustmentScreen } from './useGoalsAdjustmentScreen'

const GoalsAdjustmentScreenDesktop = lazy(() => import('./GoalsAdjustmentScreen.desktop'))

export const GoalsAdjustmentScreen: GoalsAdjustmentScreenComponent = ({ navigation, route }) => {
  const props = useGoalsAdjustmentScreen({ navigation, route })

  const Screen = GoalsAdjustmentScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
