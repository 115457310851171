/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  TRevenueCalculatorSerialized,
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  goalFields,
  percentRealizedFields,
  realizedFields,
  rvSimulatedFields,
  targetFields,
  weightFields,
  formsDefaultValues,
  activeBasesFields,
  loyaltyFields,
  acceleratorRealizedFields,
  rvAcceleratorFields,
  expensesFields
} from './constants'
import {
  RevenueCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseRevenueCalculator
} from './RevenueCalculator.types'
import {
  calculateAcceleratorRealized,
  calculateMultiplier,
  calculatePercentRealized,
  calculateRVMultiplied,
  calculateRVSimulated,
  calculateTarget,
  calculateTotal,
  calculateTotalWithPercentage,
  calculateWeightedAverage
} from '../../Utils/calculationsFunctions'
import {
  indicatorsLines,
  targets,
  baseValues,
  loyaltyValues,
  performanceValues,
  expensesValues,
  chargeGoalValues
} from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof RevenueCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// check if all values are zero, if so, it should reset with form default values
const areAllValuesZero = (revenueData: TRevenueCalculatorSerialized): boolean => {
  return Object.values(revenueData).every((value) =>
    typeof value === 'object'
      ? areAllValuesZero(value as TRevenueCalculatorSerialized)
      : value === 0
  )
}

// Validation schema creation for RV calculator form fields
const RevenueCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...targetFields,
  ...goalFields,
  ...realizedFields,
  ...percentRealizedFields,
  ...rvSimulatedFields,
  ...activeBasesFields,
  ...loyaltyFields,
  ...acceleratorRealizedFields,
  ...rvAcceleratorFields,
  ...expensesFields,
  'multiplier',
  'multipliedRV',
  'performance',
  'rvTotal'
])

// Returns the first three targets for GR users (userIsGR is true), or full targets otherwise.
const getFilteredTargets = (targets: SelectedTarget[], userIsGR?: boolean) =>
  userIsGR ? targets.slice(0, 3) : targets

export const useRevenueCalculator: UseRevenueCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: showTooltip, onOpen: openTooltip, onClose: closeTooltip } = useDisclose(false)

  const targetsList = useMemo(() => targets, [targets])

  const year_month = 202412

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<RevenueCalculatorFormFields>({
    resolver: yupResolver(RevenueCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const defaultTargetAccount = useMemo(() => targetsList[userIsGR ? 0 : 3], [userIsGR])

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(defaultTargetAccount)

  const { data: revenueCalculatorData, isLoading: isLoadingRevenueCalculatorData } =
    useGetCalculatorData(CALCULATOR_TYPE_ENUM.REVENUE, year_month, true)

  const revenueCalculatorFields = useMemo(
    () => revenueCalculatorData?.data.data,
    [revenueCalculatorData?.data.data]
  )

  useEffect(() => {
    if (
      revenueCalculatorFields &&
      !areAllValuesZero(revenueCalculatorFields as TRevenueCalculatorSerialized)
    ) {
      Object.entries(revenueCalculatorFields).forEach(([key, value]) => {
        setValue(key as keyof RevenueCalculatorFormFields, value)
      })

      if (revenueCalculatorFields.targetAccount)
        setSelectedTarget(
          targetsList.filter((target) => target.value === revenueCalculatorFields.targetAccount)[0]
        )
    }
  }, [revenueCalculatorFields])

  const { mutateAsync: calculatorMutateAsync, isLoading: isSendingCalculator } =
    useSendCalculatorSimulationMutation(CALCULATOR_TYPE_ENUM.RESULTS, year_month)

  const {
    mutate: resetRevenueCalculatorSimulation,
    isLoading: isResettingRevenueCalculatorSimulation
  } = useResetCalculatorSimulationMutation({
    calculatorType: CALCULATOR_TYPE_ENUM.REVENUE,
    year_month
  })

  const [linesFulfilled, setLinesFulfilled] = useState(0)

  const [multiplierContainsInsurance, setMultiplierContainsInsurance] = useState(false)

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<RevenueCalculatorFormFields>>(
      async (formData) => {
        return calculatorMutateAsync(
          {
            calculator_json: formData,
            year_month,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.REVENUE
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formData)

          savedOnOpen()
        })
      },

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetRevenueCalculatorSimulation(
      { calculatorType: CALCULATOR_TYPE_ENUM.REVENUE, year_month },
      {
        onSuccess: () => {
          setSelectedTarget(targetsList[userIsGR ? 0 : 3])

          reset(formsDefaultValues)
        }
      }
    )
  }

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightSPF,
      formFields.weightSA,
      formFields.weightCharge,
      formFields.weightWebmotors,
      formFields.weightMoreAccounts
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightWebmotors,
    formFields.weightCharge,
    formFields.weightMoreAccounts,
    formFields.weightProduction,
    formFields.weightSA,
    formFields.weightSPF
  ])

  // --- Target Section ---
  // Function to update target values for each field
  const updateTargetValues = () => {
    setValue(
      'targetProduction',
      calculateTarget(formFields.weightProduction, formFields.targetAccount)
    )

    setValue('targetSPF', calculateTarget(formFields.weightSPF, formFields.targetAccount))

    setValue('targetSA', calculateTarget(formFields.weightSA, formFields.targetAccount))

    setValue('targetCharge', calculateTarget(formFields.weightCharge, formFields.targetAccount))

    setValue(
      'targetWebmotors',
      calculateTarget(formFields.weightWebmotors, formFields.targetAccount)
    )

    setValue(
      'targetMoreAccounts',
      calculateTarget(formFields.weightMoreAccounts, formFields.targetAccount)
    )

    const {
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetMoreAccounts
    } = getValues()

    const values = [
      targetProduction,
      targetSPF,
      targetSA,
      targetCharge,
      targetWebmotors,
      targetMoreAccounts
    ]

    return setValue('targetTotal', calculateTotal(values))
  }

  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(defaultTargetAccount)

      setValue('targetAccount', defaultTargetAccount.value)
    }
  }, [formFields.targetAccount])

  // useEffect(() => {
  //   if (revenueFields?.targetAccount === 0) {
  //     setSelectedTarget(defaultTargetAccount)

  //     setValue('targetAccount', defaultTargetAccount.value)
  //   }
  // }, [revenueFields?.targetAccount, defaultTargetAccount])

  useEffect(() => {
    if (formFields.weightTotal === 100) updateTargetValues()
  }, [formFields.weightTotal, formFields.targetAccount])

  // --- PercentRealized Section ---
  // Function to update percent realized value for a given field
  const updatePercentRealizedValue = (
    name: keyof RevenueCalculatorFormFields,
    goal: number,
    realized: number
  ) => {
    return setValue(name, calculatePercentRealized(goal, realized, name.includes('Production')))
  }

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentRealizedValue('percentRealizedSPF', formFields.goalSPF, formFields.realizedSPF)
  }, [formFields.goalSPF, formFields.realizedSPF])

  useEffect(() => {
    updatePercentRealizedValue('percentRealizedSA', formFields.goalSA, formFields.realizedSA)
  }, [formFields.goalSA, formFields.realizedSA])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedCharge',
      formFields.goalCharge,
      formFields.realizedCharge
    )
  }, [formFields.goalCharge, formFields.realizedCharge])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedWebmotors',
      formFields.goalWebmotors,
      formFields.realizedWebmotors
    )
  }, [formFields.goalWebmotors, formFields.realizedWebmotors])

  useEffect(() => {
    updatePercentRealizedValue(
      'percentRealizedMoreAccounts',
      formFields.goalMoreAccounts,
      formFields.realizedMoreAccounts
    )
  }, [formFields.goalMoreAccounts, formFields.realizedMoreAccounts])

  // --- PercentAccelerator Section ---
  // Function to update accelerator realized value for a given field
  const updatePercentAcceleratorValue = (
    name: keyof RevenueCalculatorFormFields,
    realized: number,
    accelerators: number,
    goal?: number
  ) =>
    setValue(
      name,
      calculateAcceleratorRealized(realized, accelerators, name.includes('Production'), goal)
    )

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedProduction',
      formFields.percentRealizedProduction,
      formFields.activeBasesProduction +
        formFields.loyaltyProduction +
        formFields.expensesProduction
    )
  }, [
    formFields.percentRealizedProduction,
    formFields.activeBasesProduction,
    formFields.loyaltyProduction,
    formFields.expensesProduction
  ])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedSPF',
      formFields.percentRealizedSPF,
      formFields.activeBasesSPF + formFields.loyaltySPF + formFields.expensesSPF
    )
  }, [
    formFields.percentRealizedSPF,
    formFields.activeBasesSPF,
    formFields.loyaltySPF,
    formFields.expensesSPF
  ])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedSA',
      formFields.percentRealizedSA,
      formFields.expensesSA
    )
  }, [formFields.percentRealizedSA, formFields.expensesSA])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedCharge',
      formFields.percentRealizedCharge,
      formFields.expensesCharge,
      formFields.chargeGoal
    )
  }, [formFields.percentRealizedCharge, formFields.expensesCharge, formFields.chargeGoal])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedWebmotors',
      formFields.percentRealizedWebmotors,
      formFields.expensesWebmotors
    )
  }, [formFields.percentRealizedWebmotors, formFields.expensesWebmotors])

  useEffect(() => {
    updatePercentAcceleratorValue(
      'acceleratorRealizedMoreAccounts',
      formFields.percentRealizedMoreAccounts,
      formFields.expensesMoreAccounts
    )
  }, [formFields.percentRealizedMoreAccounts, formFields.expensesMoreAccounts])

  // --- RVSimulated Section ---
  // Function to update RV simulated value for a given field
  const updateRVSimulatedValue = (
    name: keyof RevenueCalculatorFormFields,
    target: number,
    realized: number
  ) => {
    const shouldCalculate = linesFulfilled >= 2 && realized >= 100

    return setValue(name, shouldCalculate ? calculateRVSimulated(target, realized) : 0)
  }

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedProduction',
      formFields.targetProduction,
      formFields.percentRealizedProduction
    )
  }, [formFields.targetProduction, formFields.percentRealizedProduction, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvSimulatedSPF', formFields.targetSPF, formFields.percentRealizedSPF)
  }, [formFields.targetSPF, formFields.percentRealizedSPF, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvSimulatedSA', formFields.targetSA, formFields.percentRealizedSA)
  }, [formFields.targetSA, formFields.percentRealizedSA, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedCharge',
      formFields.targetCharge,
      formFields.percentRealizedCharge
    )
  }, [formFields.targetCharge, formFields.percentRealizedCharge, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedWebmotors',
      formFields.targetWebmotors,
      formFields.percentRealizedWebmotors
    )
  }, [formFields.targetWebmotors, formFields.percentRealizedWebmotors, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvSimulatedMoreAccounts',
      formFields.targetMoreAccounts,
      formFields.percentRealizedMoreAccounts
    )
  }, [formFields.targetMoreAccounts, formFields.percentRealizedMoreAccounts, linesFulfilled])

  // --- RVSimulatedTotal Section ---
  // Function to update RV simulated total value
  const updateRVSimulatedTotalValue = () => {
    const {
      rvSimulatedProduction,
      rvSimulatedSPF,
      rvSimulatedSA,
      rvSimulatedCharge,
      rvSimulatedWebmotors,
      rvSimulatedMoreAccounts
    } = getValues()

    const values = [
      rvSimulatedProduction,
      rvSimulatedSPF,
      rvSimulatedSA,
      rvSimulatedCharge,
      rvSimulatedWebmotors,
      rvSimulatedMoreAccounts
    ]

    return setValue('rvSimulatedTotal', calculateTotal(values))
  }

  useEffect(() => {
    updateRVSimulatedTotalValue()
  }, [
    formFields.rvSimulatedProduction,
    formFields.rvSimulatedSPF,
    formFields.rvSimulatedSA,
    formFields.rvSimulatedCharge,
    formFields.rvSimulatedWebmotors,
    formFields.rvSimulatedMoreAccounts
  ])

  // --- RVAccelerator Section ---
  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorProduction',
      formFields.targetProduction,
      formFields.acceleratorRealizedProduction
    )
  }, [formFields.targetProduction, formFields.acceleratorRealizedProduction, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorSPF',
      formFields.targetSPF,
      formFields.acceleratorRealizedSPF
    )
  }, [formFields.targetSPF, formFields.acceleratorRealizedSPF, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue('rvAcceleratorSA', formFields.targetSA, formFields.acceleratorRealizedSA)
  }, [formFields.targetSA, formFields.acceleratorRealizedSA, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorWebmotors',
      formFields.targetWebmotors,
      formFields.acceleratorRealizedWebmotors
    )
  }, [formFields.targetWebmotors, formFields.acceleratorRealizedWebmotors, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorCharge',
      formFields.targetCharge,
      formFields.acceleratorRealizedCharge
    )
  }, [formFields.targetCharge, formFields.acceleratorRealizedCharge, linesFulfilled])

  useEffect(() => {
    updateRVSimulatedValue(
      'rvAcceleratorMoreAccounts',
      formFields.targetMoreAccounts,
      formFields.acceleratorRealizedMoreAccounts
    )
  }, [formFields.targetMoreAccounts, formFields.acceleratorRealizedMoreAccounts, linesFulfilled])

  // --- RVAcceleratorTotal Section ---
  // Function to update RV accelerator total value
  const updateRVAcceleratorTotalValue = () => {
    const {
      rvAcceleratorProduction,
      rvAcceleratorSPF,
      rvAcceleratorSA,
      rvAcceleratorCharge,
      rvAcceleratorWebmotors,
      rvAcceleratorMoreAccounts
    } = getValues()

    const values = [
      rvAcceleratorProduction,
      rvAcceleratorSPF,
      rvAcceleratorSA,
      rvAcceleratorCharge,
      rvAcceleratorWebmotors,
      rvAcceleratorMoreAccounts
    ]

    return setValue('rvAcceleratorTotal', calculateTotal(values))
  }

  useEffect(() => {
    updateRVAcceleratorTotalValue()
  }, [
    formFields.rvAcceleratorProduction,
    formFields.rvAcceleratorSPF,
    formFields.rvAcceleratorSA,
    formFields.rvAcceleratorCharge,
    formFields.rvAcceleratorWebmotors,
    formFields.rvAcceleratorMoreAccounts
  ])

  // --- isFulfilled Section ---
  // Callback to check if a specific field is fulfilled (>= 100)
  const isFulfilled = useCallback(
    (fieldName: keyof RevenueCalculatorFormFields) => {
      return formFields[fieldName] >= 100
    },
    [formFields]
  )

  useEffect(() => {
    const fulfilledLinesCount = acceleratorRealizedFields.filter((field) =>
      isFulfilled(field)
    ).length

    setLinesFulfilled(fulfilledLinesCount)
  }, [...acceleratorRealizedFields.map((field) => formFields[field]), isFulfilled])

  // --- activeBases & loyalty & expenses Section ---
  useEffect(() => {
    setValue('activeBasesSPF', formFields.activeBasesProduction)
  }, [formFields.activeBasesProduction])

  useEffect(() => {
    setValue('loyaltySPF', formFields.loyaltyProduction)
  }, [formFields.loyaltyProduction])

  useEffect(() => {
    setValue('expensesSPF', formFields.expensesProduction)

    setValue('expensesSA', formFields.expensesProduction)

    setValue('expensesCharge', formFields.expensesProduction)

    setValue('expensesWebmotors', formFields.expensesProduction)

    setValue('expensesMoreAccounts', formFields.expensesProduction)
  }, [formFields.expensesProduction])

  // --- containsInsurance & multiplier & multipliedRV Section ---
  useEffect(() => {
    const containsInsurance = calculateWeightedAverage(
      formFields.weightSPF,
      formFields.acceleratorRealizedSPF,
      formFields.weightSA,
      formFields.acceleratorRealizedSA
    )

    setMultiplierContainsInsurance(containsInsurance)
  }, [
    formFields.weightSPF,
    formFields.acceleratorRealizedSPF,
    formFields.weightSA,
    formFields.acceleratorRealizedSA
  ])

  useEffect(() => {
    setValue('multiplier', calculateMultiplier(linesFulfilled, multiplierContainsInsurance))
  }, [formFields.rvAcceleratorTotal, linesFulfilled, multiplierContainsInsurance])

  useEffect(() => {
    const newMultipliedRV = calculateRVMultiplied(
      formFields.rvAcceleratorTotal,
      formFields.multiplier
    )

    setValue('multipliedRV', newMultipliedRV)
  }, [formFields.multiplier, formFields.rvAcceleratorTotal])

  // --- RV Total Section ---
  useEffect(() => {
    const newTotalRV = calculateTotalWithPercentage(formFields.multipliedRV, formFields.performance)

    setValue('rvTotal', newTotalRV)
  }, [formFields.performance, formFields.multipliedRV])

  const filteredTargets = useMemo(
    () => getFilteredTargets(targetsList, userIsGR),
    [targetsList, userIsGR]
  )

  return {
    isMobile,
    userIsGR,
    control,
    errors,
    isDirty,
    targets: filteredTargets,
    selectedTarget,
    setSelectedTarget,
    openTooltip,
    closeTooltip,
    showTooltip,
    handleConfirmSimulation,
    handleResetSimulation,
    indicatorsLines,
    weightFields,
    targetFields,
    goalFields,
    realizedFields,
    percentRealizedFields,
    rvSimulatedFields,
    activeBasesFields,
    loyaltyFields,
    acceleratorRealizedFields,
    rvAcceleratorFields,
    baseValues,
    loyaltyValues,
    performanceValues,
    expensesFields,
    expensesValues,
    chargeGoalValues,
    multiplierContainsInsurance,
    isLoading: isLoadingRevenueCalculatorData,
    isSending: isSendingCalculator || isResettingRevenueCalculatorSimulation,
    successSave,
    savedIsOpen,
    savedOnClose
  }
}
